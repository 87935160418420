var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(context){return [_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit(context)}}},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ชื่อไอคอนกิจกรรม")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ชื่อไอคอนกิจกรรม","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"house_icon_activity_title","placeholder":"ชื่อไอคอนกิจกรรม"},model:{value:(_vm.formData.house_icon_activity_title),callback:function ($$v) {_vm.$set(_vm.formData, "house_icon_activity_title", $$v)},expression:"formData.house_icon_activity_title"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                  "+_vm._s(errors[0])+"\n                ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("\n                รูปไอคอนกิจกรรม"),_c('br'),_vm._v("(.jpg, .png, .gif ขนาด 150x85 px)\n              ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ไอคอนกิจกรรม","rules":"required|lh_mimes:jpg,png,gif|lh_image_resolution:150,85"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{ref:"imageUpload",attrs:{"folder":(_vm.baseFolder + "/home_activities"),"selection-name":"house_icon_activity_image"},model:{value:(_vm.formData.house_icon_activity_image),callback:function ($$v) {_vm.$set(_vm.formData, "house_icon_activity_image", $$v)},expression:"formData.house_icon_activity_image"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                  "+_vm._s(errors[0])+"\n                ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ระบุวันที่เริ่มต้นใช้งาน Icon กิจกรรม")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"วันที่เริ่มต้นใช้งาน Icon กิจกรรม","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datepicker',{attrs:{"highlighted":{
                    dates: [
                      // Highlight an array of dates
                      _vm.highlightStartDate
                    ],
                  },"disabled-dates":_vm.disableStartDate,"format":"dd/MM/yyyy","placeholder":"วันที่เริ่มต้นใช้งาน Icon กิจกรรม"},model:{value:(_vm.formData.start_date),callback:function ($$v) {_vm.$set(_vm.formData, "start_date", $$v)},expression:"formData.start_date"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                  "+_vm._s(errors[0])+"\n                ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ระบุวันที่สิ้นสุดใช้งาน Icon กิจกรรม")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"วันที่สิ้นสุดใช้งาน Icon กิจกรรม","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('datepicker',{attrs:{"highlighted":{
                    dates: [
                      // Highlight an array of dates
                      _vm.highlightEndDate ],
                  },"disabled-dates":_vm.disableEndDate,"format":"dd/MM/yyyy","placeholder":"วันที่สิ้นสุดใช้งาน Icon กิจกรรม"},model:{value:(_vm.formData.end_date),callback:function ($$v) {_vm.$set(_vm.formData, "end_date", $$v)},expression:"formData.end_date"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                  "+_vm._s(errors[0])+"\n                ")]):_vm._e()]}}],null,true)})],1)],1),_c('vs-divider'),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดโดย")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formData.updated_by))])])],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดวันที่")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.formData.updated_at)))])])],1),_c('FormLayout',[_c('FormLabel'),_c('FormContent',[_c('vs-button',{staticClass:"mt-4",on:{"click":function($event){return _vm.submit(context)}}},[_vm._v(_vm._s(_vm.buttonLabel))])],1)],1)],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }