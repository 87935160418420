<template>
  <div>
    <vs-popup 
      :active.sync="active" 
      class="add_popup" 
      title="เพิ่มชื่อไอคอนกิจกรรม">
      <Form 
        :onCreateSuccess="onCreateSuccess" 
        :data="formData"
        :popup-active="active"
      />
    </vs-popup>
    <Table
      :onOpenAddPopup="onOpenAddPopup"
    />
  </div>
</template>

<script>
import Table from './Table'
import Form from './Form'
import { ref } from '@vue/composition-api'

export default {
  components: {
    Table,
    Form,
  },
  setup() {
    const active = ref(false)
    const formData = ref({})

    const onOpenAddPopup = (data) => {
      active.value = !active.value
      if (typeof data === 'object') {
        formData.value = {
          ...data
        }
      } else {
        formData.value.id = null
        formData.value.uuid = null
        formData.value.house_icon_activity_files = []
        formData.value.house_icon_activity_title = ''
        formData.value.house_icon_activity_image = []
        formData.value.start_date = undefined
        formData.value.end_date = undefined
        formData.value.updated_at = undefined
        formData.value.created_by = undefined
        formData.value.updated_by = undefined
      }
    }
    const onCreateSuccess = () => {
      active.value = false
    }
    return {
      active,
      onOpenAddPopup,
      onCreateSuccess,
      formData
    }
  },
}
</script>
