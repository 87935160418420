<template>
  <div>
    <ValidationObserver
      v-slot:default="context"
      ref="form"
    >
      <div class="vx-row">
        <div class="vx-col w-full">
          <form @submit.prevent="submit(context)">
            <FormLayout>
              <FormLabel>
                <span>ชื่อไอคอนกิจกรรม</span> <span class="text-danger">*</span>
              </FormLabel>
              <FormContent>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="ชื่อไอคอนกิจกรรม"
                  rules="required"
                >
                  <vs-input
                    v-model="formData.house_icon_activity_title"
                    class="w-full"
                    name="house_icon_activity_title"
                    placeholder="ชื่อไอคอนกิจกรรม"
                  />
                  <span 
                    v-if="errors.length > 0" 
                    class="text-danger text-sm">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </FormContent>
            </FormLayout>
            <FormLayout>
              <FormLabel>
                <span>
                  รูปไอคอนกิจกรรม<br >(.jpg, .png, .gif ขนาด 150x85 px)
                </span><span class="text-danger">*</span>
              </FormLabel>
              <FormContent>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="ไอคอนกิจกรรม"
                  rules="required|lh_mimes:jpg,png,gif|lh_image_resolution:150,85"
                >
                  <ImageUpload
                    v-model="formData.house_icon_activity_image"
                    ref="imageUpload"
                    :folder="`${baseFolder}/home_activities`"
                    selection-name="house_icon_activity_image"
                  />
                  <span 
                    v-if="errors.length > 0" 
                    class="text-danger text-sm">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </FormContent>
            </FormLayout>
            <FormLayout>
              <FormLabel>
                <span>ระบุวันที่เริ่มต้นใช้งาน Icon กิจกรรม</span>
                 <span class="text-danger">*</span>
              </FormLabel>
              <FormContent>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="วันที่เริ่มต้นใช้งาน Icon กิจกรรม"
                  rules="required"
                >
                  <datepicker
                    v-model="formData.start_date"
                    :highlighted="{
                      dates: [
                        // Highlight an array of dates
                        highlightStartDate
                      ],
                    }"
                    :disabled-dates="disableStartDate" 
                    format="dd/MM/yyyy"
                    placeholder="วันที่เริ่มต้นใช้งาน Icon กิจกรรม"
                  />
                  <span 
                    v-if="errors.length > 0" 
                    class="text-danger text-sm">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </FormContent>
            </FormLayout>
            <FormLayout>
              <FormLabel>
                <span>ระบุวันที่สิ้นสุดใช้งาน Icon กิจกรรม</span> <span class="text-danger">*</span>
              </FormLabel>
              <FormContent>
                <ValidationProvider 
                  v-slot="{ errors }" 
                  name="วันที่สิ้นสุดใช้งาน Icon กิจกรรม" 
                  rules="required">
                  <datepicker
                    v-model="formData.end_date"
                    :highlighted="{
                      dates: [
                        // Highlight an array of dates
                        highlightEndDate,
                      ],
                    }"
                    :disabled-dates="disableEndDate" 
                    format="dd/MM/yyyy"
                    placeholder="วันที่สิ้นสุดใช้งาน Icon กิจกรรม"
                  />
                  <span 
                    v-if="errors.length > 0" 
                    class="text-danger text-sm">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </FormContent>
            </FormLayout>
            <vs-divider />
            <FormLayout>
              <FormLabel>
                <span>แก้ไขล่าสุดโดย</span>
              </FormLabel>
              <div class="flex items-center vx-col w-full md:w-6/12">
                <span class="mt-2"> {{formData.updated_by}}</span>
              </div>
            </FormLayout>
            <FormLayout>
              <FormLabel>
                <span>แก้ไขล่าสุดวันที่</span>
              </FormLabel>
              <div class="flex items-center vx-col w-full md:w-6/12">
                <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
              </div>
            </FormLayout>
            <FormLayout>
              <FormLabel/>
              <FormContent>
                <vs-button 
                  class="mt-4" 
                  @click="submit(context)">{{
                    buttonLabel
                  }}</vs-button>
              </FormContent>
            </FormLayout>
          </form>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import get from 'lodash/get'
import { reactive, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import Datepicker from 'vuejs-datepicker'
import ImageUpload from '@/components/upload/ImageUpload'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import env from '@/env'

export default {
  name: 'Form',
  components: {
    ValidationObserver,
    ImageUpload,
    Datepicker,
  },
  props: {
    onCreateSuccess: {
      type: Function,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => ({})
    },
    popupActive: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const { moment } = useDatepicker()
    const { addItem, formatDate } = useCrud(ctx, 'houseIconActivity', {
      fetchOnMount: false,
      onModifyItemSuccess: props.onCreateSuccess,
    })
    
    const initialFormDataValue = {
      uuid: null,
      id: null,
      house_icon_activity_title:  '',
      house_icon_activity_image: [],
      start_date: null,
      end_date: null,
      updated_at: null,
      created_by: null,
      updated_by: null,
    }

    const formData = reactive(initialFormDataValue)

    // const initialFormData = computed(() => {
    //   console.log('props: ', props)
    //   const activityIcons = get(props, 'data.house_icon_activity_files[0].image[0]')

    //   if (ctx.refs.imageUpload && !activityIcons) ctx.refs.imageUpload.cldUpload.assets.value = []
    //   return {
    //     uuid: props.data.uuid,
    //     id: props.data.id,
    //     house_icon_activity_title: props.data.house_icon_activity_title || '',
    //     house_icon_activity_image: activityIcons ? [activityIcons] : [],
    //     start_date: moment(props.data.start_date).isBefore(new Date()) ? moment().format() : props.data.start_date,
    //     end_date: moment(props.data.end_date).isBefore(new Date()) ? moment().format() : props.data.end_date,
    //   }
    // })

    const submit = ({ validate }) =>
      addItem(
        { validate },
        {
          ...formData,
          house_icon_activity_files: [
            {
              attribute_code: 'house_icon_activity_image',
              image_alt_text: get(formData, ['house_icon_activity_image', 0, 'image_alt_text'], {}),
              ...formData.house_icon_activity_image,
            },
          ],
        },
      )
    const buttonLabel = computed(() => {
      return formData.id ? 'แก้ไข' : 'เพิ่ม'
    })

    const baseFolder = computed(() => env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)

    const highlightStartDate = computed(() => {
      return formData.start_date
        ? new Date(formData.start_date)
        : new Date()
    })

    const highlightEndDate = computed(() => {
      const today = moment()
      return formData.end_date && moment(formData.end_date).isSameOrAfter(today)
        ? new Date(formData.end_date)
        : new Date()
    })

    const disableStartDate = computed(() => {
      const max = formData.end_date ? moment(formData.end_date).add(1, 'd').format('DD/MM/YYYY') : null
      // const min = moment().format('DD/MM/YYYY')

      const dateArray = max ? max.split('/') : null
      // const minArray = min ? min.split('/') : null
      return { from: max ? new Date(dateArray[2], parseInt(dateArray[1]) - 1, parseInt(dateArray[0])) : null }
    })

    const disableEndDate = computed(() => {
      const min = formData.start_date ? moment(formData.start_date).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')
      const dateArray = min.split('/')
      return { to: new Date(dateArray[2], parseInt(dateArray[1]) - 1, parseInt(dateArray[0])) }
    })

    watch(
      () => props.popupActive,
      (value) => {
        if (!value && ctx.refs.form && ctx.refs.form.reset) {
          ctx.refs.form.reset()
          // formData.value.id = null
          // formData.value.uuid = null
          // formData.value.house_icon_activity_title = ''
          // formData.value.house_icon_activity_image = []
          ctx.refs.imageUpload.cldUpload.assets.value = []
          // formData.value.start_date = undefined
          // formData.value.end_date = undefined
        }
      }
    )

    watch(
      () => props.data,
      (value) => {
        const activityIcons = get(value, 'house_icon_activity_files[0].image[0]')
        if (ctx.refs.imageUpload && !activityIcons) ctx.refs.imageUpload.cldUpload.assets.value = []
        formData.uuid = value.uuid
        formData.id = value.id
        formData.house_icon_activity_title = value.house_icon_activity_title || ''
        formData.house_icon_activity_image = activityIcons ? [activityIcons] : []
        formData.start_date = value.start_date
        formData.end_date = value.end_date
        formData.updated_at = value.updated_at
        formData.created_by = value.created_by
        formData.updated_by = value.updated_by
      },
      { deep: true }
    )

    return {
      submit,
      formData,
      buttonLabel,
      useDatepicker: useDatepicker(),
      baseFolder,
      disableStartDate,
      highlightStartDate,
      disableEndDate,
      highlightEndDate,
      formatDate
    }
  },
}
</script>
<style lang="scss"></style>
