<template>
  <div id="lh-table">
    <PopupLanguage
      v-model="isPopupDuplicateActive"
      :on-click-btn="handlerDuplicateContent"
      name="property-asset-project-create"
    />
    <custom-table 
      ref="table" 
      :max-items="itemsPerPage" 
      :data="items" 
      :searchKeys="searchKeys"
      pagination 
      search>
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <AddNewContent
          :on-click="onOpenAddPopup"
          name="houseIconActivity"
          button-label="เพิ่มชื่อไอคอนกิจกรรม"
        />
        <!-- ITEMS PER PAGE -->
        <LhDropdownPerPage 
          :items="items" 
          :items-per-page="itemsPerPage" 
          :current-page="currentPage" 
          :queried-items="queriedItems"
          @click="(page) => itemsPerPage = page"
        /> 
      </div>
      <template slot="thead">
        <vs-th sort-key="logo">Icon</vs-th>
        <vs-th sort-key="house_icon_activity_title">ชื่อไอคอนกิจกรรม</vs-th>
        <vs-th sort-key="">วันที่เริ่ม - วันที่สิ้นสุด</vs-th>
        <vs-th sort-key="updated_at">วันที่แก้ไขล่าสุด</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr 
            v-for="(tr, indextr) in data" 
            :data="tr" 
            :key="indextr"
            class="h-48"
          >
            <td class="td custom-table--td" @click.stop="updateData(tr)" width="20%">
              <cld-image
                :cloud-name="cldUpload.cldName.value"
                :public-id="get(tr, ['house_icon_activity_files', 0, 'image', 0, 'public_id'], '')"
                class="icon-logo"
              >
                <cld-transformation width="150" crop="fill" />
              </cld-image>
            </td>
            <td class="td custom-table--td" @click.stop="updateData(tr)">{{ tr.house_icon_activity_title }}</td>
            <td class="td custom-table--td" @click.stop="updateData(tr)">{{ tr.duration }}</td>
            <td class="td custom-table--td" @click.stop="updateData(tr)">{{ updatedAtDatetimeFormat(tr.updated_at) }}</td>
            <vs-td class="whitespace-no-wrap cursor-default">
              <div class="flex space-x-2">
                <feather-icon
                  class="cursor-pointer"
                  icon="CopyIcon"
                  svg-classes="w-5 h-5 hover:text-primary stroke-current"
                  @click.prevent="duplicateContent(tr.uuid)"
                />
                <feather-icon
                  icon="EditIcon"
                  svg-classes="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click.prevent="updateData(tr)"
                />
                <feather-icon
                  icon="TrashIcon"
                  class="cursor-pointer"
                  svg-classes="w-5 h-5 stroke-current"
                  @click.prevent="deleteData(tr.uuid)"
                />
              </div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </custom-table>
  </div>
</template>

<script>
/* eslint-disable */
import { computed, ref, onMounted } from '@vue/composition-api'
import filter from 'lodash/filter'
import map from 'lodash/map'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import AddNewContent from '@/components/AddNewContent'
import { createFolder } from '@/helper/damUtils'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import useCldUpload from '@/use/useCldUpload'
import { CldImage, CldTransformation } from 'cloudinary-vue'
import PopupLanguage from '@/components/PopupLanguage'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import CustomTable from '@/components/CustomTable'

export default {
  components: {
    AddNewContent,
    CldImage,
    CldTransformation,
    PopupLanguage,
    LhDropdownPerPage,
    CustomTable
  },
  props: {
    onOpenAddPopup: Function
  },
  setup(props, ctx) {
    const cldUpload = useCldUpload(ctx)
    onMounted(async () => {
      ctx.root.$vs.loading()
      await createFolder(``, ['home_activities'])
      ctx.root.$vs.loading.close()
    })

    const crudFunction = useCrud(ctx, 'houseIconActivity')
    const { moment, updatedAtDatetimeFormat, dateRangeFormat } = useDatepicker()

    const items = computed(() => {
      return map(crudFunction.result.value, (item) => ({
        ...item,
        duration: dateRangeFormat(item.start_date, item.end_date),
      }))
    })

    const updateData = (data) => {
      props.onOpenAddPopup(data)
    }

    const handlerDuplicateContent = async (lang) => {
      await ctx.root.$store.dispatch('locale/changeLang', lang)
      const id = crudFunction.contentDuplicateId.value
      const listData = crudFunction.result.value

      if (!isEmpty(listData)) {
        const data = listData.find((item) => {
          return item.uuid === id
        })

        const image = get(data, ['house_icon_activity_files', 0, 'image'])
        let title = `${data.house_icon_activity_title}_copy`

        let canUseTitle = false
        await ctx.root.$store.dispatch('houseIconActivity/fetch')
        
        while (!canUseTitle) {
          canUseTitle = crudFunction.result.value.every((item) => {
            return item.house_icon_activity_title !== title
          })

          if (!canUseTitle) title = `${title}_copy`
        }

        const prepareData = {
          ...omit(data, ['uuid', 'id']),
          house_icon_activity_title: title,
          house_icon_activity_files: [
            {
              attribute_code: 'house_icon_activity_image',
              image_alt_text: get(image, [0, 'image_alt_text'], {}),
              ...image,
            },
          ],
          id: null,
          lang
        }

        const activeUser = ctx.root.$store.state.AppActiveUser
        const email = get(activeUser, 'email', '')
        prepareData.created_by = email
        prepareData.updated_by = email

        ctx.root.$vs.loading()
        const result = await ctx.root.$store.dispatch('houseIconActivity/addItem', prepareData)
        const newData = await ctx.root.$store.dispatch('houseIconActivity/fetchOne', result)

        const newImage = get(newData, ['house_icon_activity_files', 0, 0], null)
        const duplicateData = {
          ...newData,
          house_icon_activity_title: get(newData, [lang, 'house_icon_activity_title'], ''),
          house_icon_activity_files: [
            {
              image: [
                newImage
              ]
            }
          ],
        }

        await ctx.root.$store.dispatch('houseIconActivity/fetch')
        await ctx.root.$vs.loading.close()
        

        props.onOpenAddPopup(duplicateData)
      }
    }

    return {
      ...crudFunction,
      items,
      updatedAtDatetimeFormat,
      updateData,
      get,
      cldUpload,
      handlerDuplicateContent,
      searchKeys: ['house_icon_activity_title', 'duration']
    }
  },
}
</script>
